let $ = require('jquery');
require('slick-carousel');
require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');

$( document ).ready(function() {

    $('.promobox-slider').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });

    $('.service-promobox-slider').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });
});